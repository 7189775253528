import { createContext } from "react";
import en from "../languages/en.json";


export const LanguageContext = createContext({
    languageCode:"en",
    strings: en,
    setLanguage:(lang:string)=>{
        
    }
})

