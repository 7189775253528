/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  Image,
  LoadingOverlay,
  RingProgress,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import "./typewriter.css";

type TypeWriterProps = {
  texts: string[];
  images: string[];
  typeSpeed?: number;
  loop?: boolean;
  ereaseSpeed?: number;
  duration?: number;
  className?: string;
  onChange?: Function;
};
function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

enum State {
  type,
  erease,
  complete,
}

export default function TypeWriter({
  texts,
  typeSpeed = 10,
  className,
  loop = false,
  images,
}: TypeWriterProps) {
  const [current, setCurrent] = useState("");
  const [index, setIndex] = useState(0);
  const [status, setStatus] = useState(State.type);
  const [buttonTimer, setButtonTimer] = useState(0);
  const { width } = useViewportSize();
  const language = useContext(LanguageContext);

  useEffect(() => {
    if(status === State.complete && loop){
      const interval = setInterval(() => {
        setButtonTimer((prev) => {
          if (prev < 100) {
            return prev + 1;
          } else {
            clearInterval(interval);
            indexHandler(); // Auto-click the button
            return prev;
          }
        });
      }, 120); // Adjust timing (e.g., 100ms increments)
      return () => clearInterval(interval); // Cleanup on component unmount or dependency change
    }
    if(!loop){
      setButtonTimer(0)
    }
  }, [status, loop]);


  if (status === State.type) {
    type(texts[index]);
  }

  function currentHandler(text: string, length: number) {
    setCurrent(text.slice(0, length));
  }

  function indexHandler() {
    setButtonTimer(0)
    let temp = index;
    if (index < texts.length - 1) {
      setIndex(temp + 1);
    } else {
      setIndex(0);
    }
    setCurrent("");
    setStatus(State.type);
  }

  function type(text: string) {
    if (current.length < text.length) {
      sleep(500 / typeSpeed).then(() => {
        currentHandler(text, current.length + 1);
      });
    } else {
      setStatus(State.complete);
    }
  }

  return (
    <Grid justify="center" align="center">
      <Grid.Col  display={"flex"} sx={{ justifyContent: "center" }} md={6}>
        <Box pos="relative"
        >
          <LoadingOverlay
            visible={status !== State.complete}
            color="dark"
            overlayBlur={Math.floor((texts[index].length / (current.length === 0  ? 1 : current.length))*10)}
            loader={<RingProgress
            size={80}
            thickness={8}
              sections={[
                {
                  value: (current.length / texts[index].length) * 100,
                  color: "black",
                },
              ]}
            />}
          />
          <Image
            sx={{ minHeight: 400, backgroundColor: "white" }}
            width={400}
            src={images[index]}
          ></Image>
        </Box>
      </Grid.Col>

      <Grid.Col md={6}>
        <Stack sx={width < 992 ? { minHeight: 180 } : {}}>
          <Title
            sx={() => ({
              background: "black",
              color: "white",
            })}
            ta="left"
            order={width > 600 ? 2 : 3}
            fw={700}
          >
            <Text className={className}>
              {status === State.complete ? texts[index] : current}
              <span className="blinkingCursor">|</span>
            </Text>
          </Title>
          {status === State.complete ? (
            <Button
              pl={25}
              pr={25}
              sx={{
                width: "fit-content",
                borderRadius: 100,
                color: "white",
                borderColor: "white",
                background: `linear-gradient(90deg, #333333 ${buttonTimer}%, transparent ${buttonTimer+1}%)`,
                transition: "0.4s",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "white",
                },
              }}
              onClick={() => {
                indexHandler();
              }}
            >
              {language.strings.typewriterNextButton}
            </Button>
          ) : (
            <></>
          )}
        </Stack>
      </Grid.Col>
    </Grid>
  );
}
