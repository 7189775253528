import { Box, Stack, Text, Tooltip } from "@mantine/core";

type SkillButtonProps = {
  icon: JSX.Element;
  name: string;
  color: string;
  description: string;
};

export default function SkillButton({
  icon,
  name,
  color,
  description,
}: SkillButtonProps) {
  return (
    <Tooltip withArrow multiline label={description}>
      <Stack
        spacing={4}
        sx={{
          width: "fit-content",
          alignItems: "center",
          color: "gray",
          transition: "0.3s",
          "&:hover": {
            color: `${color}`,
          },
        }}
      >
        <Box
          sx={{
            height: 50,
            width: 50,
          }}
        >
          {icon}
        </Box>
        <Text>{name}</Text>
      </Stack>
    </Tooltip>
  );
}
