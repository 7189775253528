import { Text, Timeline } from "@mantine/core";
import { IconCircleFilled } from "@tabler/icons-react";

const style = {
  fontSize: "1.1rem",
  color: "gray",
  transition: "0.3s",
  "&:hover": {
    color: "white",
  },
};

type PDTimelineItemProps = {
  title: string;
  description: string;
  date: string;
};

type PDTimelineProps = {
  items: PDTimelineItemProps[];
};

export default function PDTimeline({ items }: PDTimelineProps) {
  return (
    <Timeline color="white" bulletSize={20} lineWidth={4}>
      {items.map((item) => {
        return (
          <Timeline.Item
          key={item.title+"_timeline"}
            sx={style}
            title={item.title}
            bullet={<IconCircleFilled size={15} />}
          >
            <Text size="sm">{item.description}</Text>
            <Text size="xs" mt={4}>
              {item.date}
            </Text>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
}
