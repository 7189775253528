import { useWindowScroll } from "@mantine/hooks";
import { useContext, useState } from "react";
import TypeWriter from "../../components/typewriter";
import image1 from "../../images/image1.png";
import image2 from "../../images/image2.png";
import image3 from "../../images/image3.png";
import image4 from "../../images/image4.png";
import image5 from "../../images/image5.png";
import image6 from "../../images/image6.png";
import project1 from "../../images/project1.png";
import project10 from "../../images/project10.png";
import project11 from "../../images/project11.png";
import project12 from "../../images/project12.png";
import project13 from "../../images/project13.jpg";
import project2 from "../../images/project2.png";
import project3 from "../../images/project3.png";
import project4 from "../../images/project4.png";
import project5 from "../../images/project5.png";
import project6 from "../../images/project6.png";
import project7 from "../../images/project7.png";
import project8 from "../../images/project8.png";
import project9 from "../../images/project9.png";

import {
  ActionIcon,
  Affix,
  Badge,
  Box,
  Divider,
  Flex,
  Grid,
  Group,
  rem,
  Stack,
  Text,
  Title,
  Tooltip,
  Transition,
} from "@mantine/core";
import {
  IconArrowUp,
  IconBrandAws,
  IconBrandGithub,
  IconBrandJavascript,
  IconBrandMysql,
  IconBrandNextjs,
  IconBrandReactNative,
  IconBrandTypescript,
  IconRepeat,
} from "@tabler/icons-react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import PDTimeline from "../../components/pdtimeline";
import ProjectCard from "../../components/projectcard";
import SkillButton from "../../components/skillbutton";
import { LanguageContext } from "../../contexts/LanguageContext";
import "./home.css";

const DIVIDER_PADDING = "40vw";




export default function Home() {
  const images = [image1, image2, image3,image4,image5,image6];
  const [scroll, scrollTo] = useWindowScroll();
  const [speed, setSpeed] = useState(1);
  const [loop, setLoop] = useState(true);
  const language = useContext(LanguageContext);

  const skills = [
    {
      icon: <IconBrandJavascript size={50} />,
      name: "Node.js",
      color: "#68a063",
      description: language.strings.descriptionNodejs
    },
    {
      icon: <IconBrandNextjs size={50} />,
      name: "Next.js",
      color: "white",
      description: language.strings.descriptionNextjs
    },
    {
      icon: <IconBrandReactNative size={50} />,
      name: "React",
      color: "#61dbfb",
      description: language.strings.descriptionReact
    },
    {
      icon: <IconBrandMysql size={50} />,
      name: "MySQL",
      color: "#00758f",
      description: language.strings.descriptionMysql
    },
    {
      icon: <IconBrandAws size={50} />,
      name: "AWS",
      color: "#FF9900",
      description: language.strings.descriptionAws
    },
    {
      icon: <IconBrandGithub size={50} />,
      name: "GitHub",
      color: "white",
      description: language.strings.descriptionGithub
    },
    {
      icon: <IconBrandTypescript size={50} />,
      name: "Typescript",
      color: "#007acc",
      description: language.strings.descriptionTypescript
    },
  ];

  const projects = [
    {...language.strings.contentProjects[0],
      img: project1,
    },
    {...language.strings.contentProjects[1],
      img: project2,
    },
    {...language.strings.contentProjects[2],
      img: project3,
    },
    {...language.strings.contentProjects[3],
      img: project4,
    },
    {...language.strings.contentProjects[4],
      img: project5,
    },
    {...language.strings.contentProjects[5],
      img: project6,
    },
    {...language.strings.contentProjects[6],
      img: project7,
    },    
    {...language.strings.contentProjects[7],
      img: project8,
    },
    {...language.strings.contentProjects[8],
      img: project9,
    },
    {...language.strings.contentProjects[9],
      img: project10,
    },
    {...language.strings.contentProjects[10],
      img: project11,
    },
    {...language.strings.contentProjects[11],
      img: project12,
    },
    {...language.strings.contentProjects[12],
      img: project13,
    },
  ];

  return (
    <>
      <Stack spacing={15} bg={"black"}>
        <Header />
        <Box
          pt={"90px"}
          pl={"8vw"}
          pr={"8vw"}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Group>
            <Tooltip label={language.strings.generationRate} openDelay={500}>
          <Flex gap={2}>
            <ActionIcon
              sx={{ width: "fit-content" }}
              onClick={() => {
                setSpeed(0.25);
              }}
              radius={0}
              variant={speed === 0.25 ? "filled" : "outline"}
            >
              <Text pr={2} pl={2} size={"xs"}>
                0.25x
              </Text>
            </ActionIcon>
            <ActionIcon
              sx={{ width: "fit-content" }}
              onClick={() => {
                setSpeed(1);
              }}
              radius={0}
              variant={speed === 1 ? "filled" : "outline"}
            >
              <Text pr={2} pl={2} size={"xs"}>
                1x
              </Text>
            </ActionIcon>
            <ActionIcon
              sx={{ width: "fit-content" }}
              onClick={() => {
                setSpeed(2);
              }}
              radius={0}
              variant={speed === 2 ? "filled" : "outline"}
            >
              <Text pr={2} pl={2} size={"xs"}>
                2x
              </Text>
            </ActionIcon>
          </Flex>
          </Tooltip>

          <Flex gap={2}>
          <Tooltip label={language.strings.loop} openDelay={500}>
            <ActionIcon
              sx={{ width: "fit-content" }}
              onClick={() => {
                setLoop((prev)=>!prev);
              }}
              radius={0}
              variant={loop ? "filled" : "outline"}
            >
              <IconRepeat stroke={1.5}/>
            </ActionIcon>
            </Tooltip>
          </Flex>
          </Group>
        </Box>
        <Box pl={"5vw"} pr={"5vw"}>
          <TypeWriter
            typeSpeed={speed * 10}
            ereaseSpeed={speed * 20}
            texts={language.strings.typewriterTexts}
            images={images}
            loop={loop}
          ></TypeWriter>
        </Box>
        <Box pl={"10vw"} pr={"10vw"} sx={{ textAlign: "center" }}>
          <Title
            pt={20}
            pb={20}
            sx={() => ({
              color: "white",
            })}
            id="about"
          >
            {language.strings.headerAbout}
          </Title>
          <Text
            sx={() => ({
              color: "gray",
              transition: "0.3s",
              "&:hover": {
                color: "white",
              },
            })}
            color="white"
            fz={"lg"}
          >
            {language.strings.contentAbout}
          </Text>
        </Box>
        <Box pl={DIVIDER_PADDING} pr={DIVIDER_PADDING}>
          <Divider my="sm" />
        </Box>
        <Box pl={"20vw"} pr={"20vw"} sx={{ textAlign: "center" }}>
          <Title
            sx={() => ({
              color: "white",
            })}
            pb={20}
            id="experiences"
          >
            {language.strings.headerExperiences}
          </Title>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <PDTimeline items={language.strings.contentExperiences}></PDTimeline>
        </Box>
        <Box pl={DIVIDER_PADDING} pr={DIVIDER_PADDING}>
          <Divider my="sm" />
        </Box>
        <Box pl={"20vw"} pr={"20vw"} sx={{ textAlign: "center" }}>
          <Title
            sx={() => ({
              color: "white",
            })}
            id="skills"
          >
            {language.strings.headerSkills}
          </Title>
          <Box pb={20}>
          <Badge color="dark"
          >
            {language.strings.subHeaderSkills}
          </Badge>
          </Box>

          <Grid grow>
            {skills.map((skill) => (
              <Grid.Col
                key={skill.name + "_grid"}
                sx={{ display: "flex", justifyContent: "center" }}
                span={4}
                md={3}
              >
                <SkillButton {...skill} />
              </Grid.Col>
            ))}
          </Grid>
        </Box>
        <Box pl={DIVIDER_PADDING} pr={DIVIDER_PADDING}>
          <Divider my="sm" />
        </Box>
        <Box
          pl={"20vw"}
          pr={"20vw"}
          sx={{
            textAlign: "center",
          }}
        >
          <Title
            sx={() => ({
              color: "white",
            })}
            pb={0}
            id="projects"
          >
            {language.strings.headerProjects}
          </Title>
          <Box pt={0} pb={20}>
          <Badge color="dark"
          >
            {language.strings.subHeaderProjects}
          </Badge>
          </Box>
          <Grid gutter={25}>
            {projects.map((project) => (
              <Grid.Col
                key={project.title + "_grid"}
                sx={{
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
                sm={6}
                lg={4}
              >
                <ProjectCard {...project} />
              </Grid.Col>
            ))}
          </Grid>
        </Box>

        <Footer />
        <Affix position={{ bottom: rem(20), right: rem(20) }}>
          <Transition transition="slide-up" mounted={scroll.y > 0}>
            {(transitionStyles) => (
              <ActionIcon
                size={"xl"}
                radius={100}
                variant="filled"
                style={transitionStyles}
                onClick={() => {
                  scrollTo({ y: 0 });
                }}
              >
                <IconArrowUp></IconArrowUp>
              </ActionIcon>
            )}
          </Transition>
        </Affix>
      </Stack>
    </>
  );
}
