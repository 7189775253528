import { MantineProvider } from "@mantine/core";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { LanguageContext } from "./contexts/LanguageContext";
import en from "./languages/en.json";
import tr from "./languages/tr.json";
import FourOhFour from "./pages/404/fourohfour";
import Home from "./pages/home/home";

function App() {
  let defaultLang = {
    langPack: en,
    langCode: "en",
  };

  if (navigator.language.toLocaleLowerCase().includes("tr")) {
    defaultLang = {
      langPack: tr,
      langCode: "tr",
    };
  }

  const [language, setLanguage] = useState(defaultLang);

  function languageHandler(langCode:string,langPack: any) {
    setLanguage({
      langPack: langPack,
      langCode: langCode,
    });
  }

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        fontFamily: "Verdana, sans-serif",
        fontFamilyMonospace: "Monaco, Courier, monospace",
        headings: { fontFamily: "Greycliff CF, sans-serif" },
        fontSizes: {
          xs: "0.6rem",
          sm: "0.75rem",
          md: "0.9rem",
          lg: "1rem",
          xl: "1.2rem",
          xxl: "2rem",
        },
      }}
    >
      <LanguageContext.Provider
        value={{
          languageCode: language.langCode,
          strings: language.langPack,
          setLanguage: (lang: string) => {
            switch (lang) {
              case "tr":
                languageHandler("tr",tr);
                break;
              case "en":
                languageHandler("en",en);
                break;
              default:
                break;
            }
          },
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<Home/>} />
              <Route path="*" element={<FourOhFour/>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </LanguageContext.Provider>
    </MantineProvider>
  );
}

export default App;
