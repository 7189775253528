import { Box, Text, Title, Stack, Flex, Button } from "@mantine/core";
import { LanguageContext } from "../../contexts/LanguageContext";
import { useContext } from "react";
export default function FourOhFour() {
  const language = useContext(LanguageContext);
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
        textAlign: "center",
      }}
    >
        <Flex sx={{position: "fixed",
        top: 10,
        right: 0,
        zIndex: 1,
        display: "block",
        width: "100%",}}>
        <Title
            order={2}
            variant="gradient"
            ta={"center"}
            gradient={{ from: "whitesmoke", to: "gray", deg: 180 }}
            color="white"
          >
            tscburak.dev
          </Title>
        </Flex>
      <Stack sx={{ alignItems: "center" }} spacing={10}>
        <Title color="white">{language.strings.forohfourDescription}</Title>
        <Button
          radius={100}
          sx={{borderColor:"white",color:"white" ,width: "fit-content",transition:"0.3s","&:hover":{
            backgroundColor:"white",
            color:"black"
          }}}
          variant="outline"
          component="a"
          href="/"

        >
          <Text>
            {language.strings.forohfourGoback}
          </Text>
        </Button>
      </Stack>
    </Box>
  );
}
