import { Box, Text } from "@mantine/core";
import { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";

export default function Footer() {
  const language = useContext(LanguageContext)

  return (
    <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}} h={100}>
      <Text sx={{color:"gray",transition:"0.3s","&:hover":{
        color:"white",
        cursor:"default"
      }}}>{language.strings.footerText}</Text>
    </Box>
  );
}
