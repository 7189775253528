import {
  ActionIcon,
  Badge,
  Box,
  Card,
  Group,
  Image,
  Text,
} from "@mantine/core";
import {
  IconBrandGithub,
  IconBrandLinkedin,
  IconWorld,
} from "@tabler/icons-react";
type ProjectCardProps = {
  title: string;
  img?: string;
  description: string;
  techs: string[];
  githublink?: string;
  linkedinlink?: string;
  websitelink?: string;
};


export default function ProjectCard({
  title,
  img,
  description,
  techs,
  githublink,
  linkedinlink,
  websitelink,
}: ProjectCardProps) {
  return (
    <Card
      sx={{
        cursor:"context-menu",
        position: "relative",
        width: 280,
        textAlign: "left",
        backgroundColor: "transparent",
        color: "white",
        transition: "0.3s",
        "&:hover": {
          backgroundColor: "white",
          color: "black",
        },
      }}
      shadow="sm"
      radius="lg"
      withBorder
    >
      <Card.Section pb={15}>
        <Image withPlaceholder height={160} src={img} />
      </Card.Section>
      <Text weight={500}>{title}</Text>
      <Text size={"sm"} color="dimmed">
        {description}
      </Text>
      <Group pt={8} pb={55} spacing={5}>
        {techs.map((tech) => (
          <Badge
            key={tech + "_badge"}
            sx={{
              borderColor: "gray",
              backgroundColor: "transparent",
              color: "gray",
            }}
            size="xs"
          >
            {tech}
          </Badge>
        ))}
      </Group>
      <Box sx={{ justifyContent: "center", display: "flex" }}>
        <Group sx={{ position: "absolute", bottom: 10 }} pt={15}>
          {typeof websitelink !== "undefined" ? (
            <ActionIcon
              component="a"
              target="_blank"
              href={websitelink}
              size={"lg"}
              p={3}
              sx={{
                backgroundColor: "black",
                borderColor: "white",
                transition: "0.3s",
                color: "white",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "black",
                },
              }}
              radius={100}
            >
              <IconWorld size={26.4} />
            </ActionIcon>
          ) : (
            <></>
          )}
          {typeof githublink !== "undefined" ? (
            <ActionIcon
              component="a"
              target="_blank"
              href={githublink}
              size={"lg"}
              p={3}
              sx={{
                backgroundColor: "black",
                borderColor: "white",
                transition: "0.3s",
                color: "white",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "black",
                },
              }}
              radius={100}
            >
              <IconBrandGithub size={26.4} />
            </ActionIcon>
          ) : (
            <></>
          )}
          {typeof linkedinlink !== "undefined" ? (
            <ActionIcon
              component="a"
              target="_blank"
              href={linkedinlink}
              size={"lg"}
              p={3}
              sx={{
                backgroundColor: "black",
                color: "#0e76a8",
                borderColor: "#0e76a8",
                transition: "0.3s",
                "&:hover": {
                  backgroundColor: "#0e76a8",
                  color: "white",
                },
              }}
              radius={100}
            >
              <IconBrandLinkedin size={26.4} />
            </ActionIcon>
          ) : (
            <></>
          )}
        </Group>
      </Box>
    </Card>
  );
}
